<template>
  <div class="search-form-container">
    <form
      class="search-form"
      action="/analysis/"
      method="GET"
      onsubmit="return false"
    >
      <input
        ref="SearchInput"
        v-model.trim="inputKeyword"
        class="search-form-input"
        type="search"
        name="stockid"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        :spellcheck="false"
        :placeholder="placeHolderText"
        @keydown.up.prevent="pressKeyUpHandler"
        @keydown.down.prevent="pressKeyDownHandler"
        @keydown.enter.prevent="pressKeyEnterHandler"
        @focus="onFocusHandler"
        @blur="isInputOnFocus = false"
      >

      <a
        class="search-form-button navi-menu-icon icon-font-search"
        @click="autocompleteDirectHandler"
      />

      <Autocomplete
        v-show="shouldShowAutocomplete"
        ref="AutocompleteList"
        :theme="theme"
        :stock-list="stockList"
        :tag-list="tagList"
        :news-list="newsList"
        :update-current-selected-item="updateCurrentSelectedItem"
        :click-autocomplete-handler="autocompleteDirectHandler"
      />
    </form>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'

import SearchPanelMixin from '../../mixins/searchPanel'
import { NAVI, WELCOME, US_STOCK } from '../../constants/desktop_autocomplete_theme'
import * as AUTOCOMPLETE_CATEGORY from '../../constants/autocomplete_category'

import Autocomplete from './Autocomplete.vue'

export default {
  name: 'SearchForm',
  components: {
    Autocomplete,
  },
  mixins: [SearchPanelMixin],
  props: {
    theme: {
      type: String,
      default: NAVI,
      validator: (value) => [NAVI, WELCOME, US_STOCK].includes(value),
    },
    searchCallback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    shouldShowAutocomplete() {
      return !this.isRequestAutocompleteError
        && (this.stockList.length > 0
          || this.tagList.length > 0
          || this.newsList.length > 0)
        && this.inputKeyword.length > 0
    },
  },
  watch: {
    inputKeyword(newValue) {
      if (this.isInputOnFocus) this.search()
      if (newValue === '') {
        this.autocompleteResult = {}
      }
    },
  },
  methods: {
    search: debounce(function search() {
      if (this.inputKeyword !== '') {
        this.requestAutocomplete(this.inputKeyword)
      }
    }, 150),
    autocompleteDirectHandler() {
      if (!this.currentSelectedItem || typeof this.currentSelectedItem !== 'object') {
        this.$refs.SearchInput.focus()
        return
      }

      this.searchCallback(this.currentSelectedItem)

      if (this.currentSelectedItem.category === AUTOCOMPLETE_CATEGORY.STOCK) {
        if (this.isCurrentPathInAnalysisPage) {
          document.title = `${this.currentSelectedItem.localLangName}(${this.currentSelectedItem.ticker})財報分析 | 財報狗股票`
        }
        this.$refs.SearchInput.blur()
        this.inputKeyword = this.currentSelectedItem.displayContent
        this.directToAnalysis({ ticker: this.currentSelectedItem.ticker })
        this.autocompleteResult = {}
        return
      }

      if (this.currentSelectedItem.category === AUTOCOMPLETE_CATEGORY.TAG) {
        window.amplitudeEvents.clickTag({
          medium: 'search_bar',
          tagName: this.currentSelectedItem.displayContent,
          tagId: this.currentSelectedItem.url.split('/')[4] || '',
          targetUrl: this.currentSelectedItem.url,
        })
      }

      if (this.currentSelectedItem.category === AUTOCOMPLETE_CATEGORY.NEWS) {
        window.amplitudeEvents.clickStatementdogNews({
          medium: 'search_bar',
          title: this.currentSelectedItem.displayContent,
          targetUrl: this.currentSelectedItem.url,
        })
      }

      window.location.assign(this.currentSelectedItem.url)
      this.autocompleteResult = {}
    },
  },
}
</script>
