<template>
  <div
    :class="[
      'search-bar-autocomplete',
      themeClassName
    ]"
    :data-selected-id="selectedIdForGuidance"
  >
    <AutocompleteCategoryList
      v-if="stockList.length > 0"
      class="stock-list"
      title="查詢個股"
      :selected-id="selectedId"
      :list="stockList"
      :click-autocomplete-item-handler="clickAutocompleteItemHandler"
      :mouseover-autocomplete-item-handler="mouseoverAutocompleteItemHandler"
    />
    <AutocompleteCategoryList
      v-if="tagList.length > 0"
      class="tag-list"
      title="查詢投資主題"
      :selected-id="selectedId"
      :list="tagList"
      :click-autocomplete-item-handler="clickAutocompleteItemHandler"
      :mouseover-autocomplete-item-handler="mouseoverAutocompleteItemHandler"
    />
    <AutocompleteCategoryList
      v-if="newsList.length > 0"
      class="news-list"
      title="查詢新聞"
      :selected-id="selectedId"
      :list="newsList"
      :click-autocomplete-item-handler="clickAutocompleteItemHandler"
      :mouseover-autocomplete-item-handler="mouseoverAutocompleteItemHandler"
      :display-single-line-result="true"
    />
  </div>
</template>

<script>
import autocompleteMixin from '../../mixins/autocomplete'
import { NAVI, US_STOCK, WELCOME } from '../../constants/desktop_autocomplete_theme'

import AutocompleteCategoryList from './AutocompleteCategoryList.vue'

export default {
  name: 'DesktopAutocomplete',
  components: {
    AutocompleteCategoryList,
  },
  mixins: [autocompleteMixin],
  props: {
    theme: {
      type: String,
      default: NAVI,
      validator: (value) => [NAVI, US_STOCK, WELCOME].includes(value),
    },
  },
  data() {
    return {
      selectedIdForGuidance: '',
    }
  },
  computed: {
    themeClassName() {
      switch (this.theme) {
      case NAVI:
        return 'navigation'
      case US_STOCK:
        return 'us-stock'
      case WELCOME:
        return 'welcome'
      default:
        return 'navigation'
      }
    },
  },
  watch: {
    selectedId(newValue) {
      if (newValue) {
        this.selectedIdForGuidance = newValue
      }
    },
  },
}
</script>

<style scoped lang="scss">
.search-bar-autocomplete {
  display: block;
  border: 1px solid #dfdfdf;
  box-shadow: 0 1px 3px rgba(67, 67, 67, 0.1);

  &.navigation {
    position: absolute;
    top: 100%;
    width: 100%;
    min-width: 100%;
    z-index: 99;
    background-color: #fff;
    box-sizing: border-box;

    @media screen and (max-width: 48em) {
      min-width: 220px;
    }
  }
  &.us-stock {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 90%;
    left: 4.5%;
    z-index: 99;
    border: 1px solid #dfdfdf;

    @media screen and (min-width: 48em) {
      width: 26rem;
      left: calc(50% - 13rem);
    }
    :deep(.autocomplete-category-list) {
      box-shadow: none;
    }
    :deep(.autocomplete-category-list-item) {
      &.is-focusing {
        background-color: #F7B500;
        color: #fff;
        .autocomplete-category-list-item-content > .highlight {
          color: #fff;
        }
      }
    }
    :deep(.autocomplete-category-list-item-content) {
      > .highlight {
        color: #232323;
        font-weight: normal;
      }
    }
  }
  &.welcome {
    position: absolute;
    width: inherit;
    @media screen and (max-width: 48em) {
      left: 6%;
      .autocomplete-category-list {
        margin-top: -1px;
        box-shadow: none;
      }
    }
  }
}
</style>
