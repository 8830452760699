<!-- eslint-disable vue/no-v-html -->
<template>
  <ul class="autocomplete-category-list">
    <li class="autocomplete-category-list-item headline">
      <span class="headline-title">{{ title }}</span>
    </li>
    <li
      v-for="item in list"
      :key="item.id"
      :class="['autocomplete-category-list-item', { 'is-focusing': item.id === selectedId }]"
      @mouseover="mouseoverAutocompleteItemHandler(item)"
      @click="clickAutocompleteItemHandler(item)"
    >
      <span
        class="autocomplete-category-list-item-content"
        :class="{ 'single-line-result': displaySingleLineResult }"
        v-html="item.highlightContent"
      />

      <span
        v-if="item.label"
        class="autocomplete-category-list-item-label"
      >
        {{ item.label }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AutocompleteCategoryList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    selectedId: {
      type: String,
      default: '',
    },
    clickAutocompleteItemHandler: {
      type: Function,
      required: true,
    },
    mouseoverAutocompleteItemHandler: {
      type: Function,
      required: true,
    },
    displaySingleLineResult: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.autocomplete-category-list {
  list-style: none outside none;
  background: #fff;
  font-size: 15px;
  line-height: 164%;
  padding: 3px 0px;
  text-align: left;

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
  @media screen and (max-width: 35.5em) {
    box-shadow: 0 1px 3px rgba(#434343, 0.3);
    border: none;
  }
}
.autocomplete-category-list-item {
  color: #232323;
  display: flex;
  align-items: flex-start;
  padding: 6px 12px;
  position: relative;
  cursor: pointer;

  &.is-focusing {
    background: #EEEEEE;
    color: #000;
  }

  .top-post {
    color: #fff;
    display: inline-block;
    padding: 0px 9px;
    font-size: 12px;
    line-height: 20px;
    background: #0386f4;
    margin: 2px 0 0 8px;
    border-radius: 14px;

    @media screen and (max-width: 1024px) {
      margin-top: 1px;
    }
  }
}
.autocomplete-category-list-item-content {
  flex: 1;
  :deep(.highlight) {
    font-weight: 700;
    color: #0386f4;
    background-color: rgba(255,255,255,0);
  }

  &.single-line-result {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.autocomplete-category-list-item.headline {
  padding: 0px 6px;
  margin: 12px 0 12px;
  line-height: 0;
  border-bottom: 1px solid #ebebeb;

  .headline-title {
    display: table;
    padding: 3px 6px;
    background: #fff;
    margin: 0 0 -3px;
    font-size: 13px;
    font-weight: bold;
    color: #9d9d9d;
  }
}
.autocomplete-category-list-item-label {
  flex: 0;
  word-break: keep-all;
  display: inline-block;
  position: relative;
  top: 2px;
  padding: 0px 9px;
  font-size: 12px;
  line-height: 20px;
  background-color: #0386f4;
  color: #fff;
  border-radius: 14px;
}
</style>
